
import { useAdminsQuery } from "../queries";
import { Button, Table, TableRow, TableCell } from "@material-ui/core";
import { AdminsContext } from "./Admins";
import { useContext } from "react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EmailIcon from '@material-ui/icons/Email';

function AdminList() {

    const { showSendEmail, showDelete, secret, setSecret } = useContext(AdminsContext);
    const { data, refetch } = useAdminsQuery(secret);

    const roles = ["viewer", "moderator", "admin"];
    const setRole = async (id, role) => {
        const postData = { id, role, secret };
        const res = await fetch("/api/set-admin-role", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        const resObj = await res.json()
        if(resObj.err){
            alert(resObj.err);
            setSecret("");
        }

        refetch();
    }

    const sendMail = async (row) => {
        const confirmMail = window.confirm(
            `Send email to ${row.email}?`
        );
        if (!confirmMail) return;
        const postData = {
            email: row.email,
            sendMail: true,
            secret
        }
        const res = await fetch("/api/check-admin", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        const resObj = await res.json()
        if(resObj.err){
            alert(resObj.err);
            setSecret("");
        }
        else{
            alert(resObj.msg);
        }
    }

    const deleteRow = async (row) => {
        const confirmDelete = window.confirm(
            `Delete account ${row.email}?`
        );
        if (!confirmDelete) return;
        const postData = {
            id: row.id,
            secret
        }
        const res = await fetch("/api/del-admin", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        const resObj = await res.json()
        if(resObj.err){
            alert(resObj.err);
            setSecret("");
        }
        refetch();
    }



    return (
        <Table style={{ maxWidth: "800px" }}>
            {data?.map(row =>
                <TableRow key={row.id}>
                    <TableCell style={{ width: "250px" }}>{row.email}</TableCell>
                    {roles.map(role => (
                        <TableCell>
                            <Button disabled={!secret} variant={row.role === role ? "contained" : "default"} color={row.role === role ? "primary" : "default"} onClick={() => setRole(row.id, role)}>{role}</Button>
                        </TableCell>
                    ))}
                    {showSendEmail &&
                        <TableCell>
                            {row.role &&
                                <Button onClick={() => sendMail(row)}><EmailIcon /></Button>
                            }
                        </TableCell>
                    }
                    {showDelete &&
                        <TableCell><Button onClick={() => deleteRow(row)}><HighlightOffIcon /></Button></TableCell>
                    }
                </TableRow>
            )}
        </Table>);

    // <DataGrid disableColumnSelector disableSelectionOnClick disableDensitySelector style={{height: '60vh'}} rows = {data || []} columns={columns} />

}

export default AdminList;