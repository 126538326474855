import React, { useContext } from 'react';
import { useState } from 'react';
import MapGL, { Source, Layer } from 'react-map-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Paper } from '@material-ui/core';
import { useGeoOptionsQuery, useShootsQuery } from '../queries';
import { ManagerContext } from './Manager';
import ManagerMapEditor from './ManagerMapEditor';
import { isDateSelected } from './utils';
import ManagerMapTooltip from './ManagerMapTooltip';
const MAPBOX_TOKEN = 'pk.eyJ1Ijoic21lY2h0IiwiYSI6ImNrcXl5a2tkNTAyNjcycGxnbHl5YmZjcTgifQ.DyMTuFPOmKfCauOgi0N0zw';

function makeGeoJSON(data, idx, selectedDate, dataDays, enabledTool) {
  if (!data) return null;
  return {
    type: 'FeatureCollection',
    features: data.filter(d => d.is_fake === idx && d.lng !== 0 && (!selectedDate || isDateSelected(d.date, selectedDate, dataDays, enabledTool) )).map(feature => {
      return {
        "type": "Feature",
        "properties": {
          "id": feature.id,
          "value": feature.cnt
        },
        "geometry": {
          "type": "Point",
          "coordinates": [feature.lng, feature.lat]
        }
      }
    })
  }
};

export function makeBlockedAreasGeoJSON(data) {
  if (!data) return null;
  const json = {
    type: 'FeatureCollection',
    features: data.map(coordinates => {
      return {
        "type": "Feature",
        "properties":{},
        "geometry": {
          "type": "Polygon",
          coordinates
        }
      }
    })
  }
  return json;
};

function getHeatmapColors(idx){
  const r = [0, 1, 0, 1.6][idx];
  const g = [1, 0, 0, 0.6][idx];
  const b = [0, 0, 1, 0][idx];
  const colors = [[120, 80, 0], [160, 80, 1], [160, 60, 1], [160, 40, 1], [160, 20, 1], [120, 0, 1]];
  return colors.map((c,idx) => [idx / 5, 
    `rgba(${c[0] * r + c[1]}, ${c[0] * g + c[1]}, ${c[0] * b + c[1]}, ${c[2]})`]
    ).flat()
}

function ManagerMap({dataDays}) {
  const [viewport, setViewport] = useState({
    latitude: 39,
    longitude: 23.5,
    zoom: 5
  });
  const { data, refetch } = useShootsQuery(true);
  const { data: geoOptions } = useGeoOptionsQuery();
  const { dispStatus, enabledTool, addedShoots, selectedDate } = useContext(ManagerContext);

  const addShoots = (e) => {
    if (enabledTool !== 1) return;
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth() + 1;
    const d = today.getDate();
    const mz = m > 9 ? "" : "0";
    const dz = d > 9 ? "" : "0";
    const dt = `>${y}${mz}${m}${dz}${d}`;
    fetch("/api/set-shoot", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cnt: addedShoots,
        dt,
        qr: `ADM-INS-HOT-${today.getTime()}`,
        lat: e.lngLat[1],
        lng: e.lngLat[0]
      })
    }).then(() => { refetch() });

  };

  return (
    <Paper style={{ flexGrow: "1", minHeight:"400px" }} >
      <MapGL
        {...viewport}
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
        width='100%'
        height='100%'
        mapStyle="mapbox://styles/smecht/ckvshjgga11z314p83js59528"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onClick={addShoots}
      >
        {geoOptions?.selected_zone > 0 &&
          <Source type='geojson'
            id="oregonjson"
            data={["/until1509.geojson", "/after1509.geojson"][geoOptions.selected_zone - 1]}
          >
            <Layer
              type="fill"
              source="oregonjson"
              paint={{ "fill-color": "#880000", "fill-opacity": 0.4 }}
            />
          </Source>
        }

        {geoOptions?.blocked_area &&
         enabledTool !== 4 &&
          <Source type='geojson'
            id="blockedareajson"
            data={makeBlockedAreasGeoJSON(geoOptions.blocked_area)}
          >
            <Layer
              type="fill"
              source="blockedareajson"
              paint={{ "fill-color": "#ffcc00", "fill-opacity": 0.4 }}
            />
          </Source>
        }

        {[0, 1, 2, 3].filter(idx => dispStatus[idx]).map(idx => (
          <Source key={idx} type='geojson' data={makeGeoJSON(data?.details, idx, selectedDate, dataDays, enabledTool)}>
            <Layer {...{
              type: 'heatmap',
              paint: {
                'heatmap-intensity': 1,
                'heatmap-radius': 10,
                'heatmap-weight': ['interpolate', ['linear'], ['get', 'value'], 0, 0, 6, 2],
                'heatmap-opacity': 1,
                'heatmap-color': [
                  'interpolate',
                  ['linear'],
                  ['heatmap-density'],
                  ...getHeatmapColors(idx)
                ]
              },
            }} />
          </Source>
        ))}

        {enabledTool === 2 &&
          <ManagerMapEditor />
        }
        {enabledTool === 4 &&
          <ManagerMapTooltip dataDays = {dataDays}/>
        }        
      </MapGL>
    </Paper >
  )
}

export default ManagerMap;