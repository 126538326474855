import React from 'react';
import { Typography, Paper, LinearProgress, CircularProgress, Box } from '@material-ui/core';
import { useShootsQuery } from '../../queries';
import MyProgressBar from './MyProgressBar';

function Info() {
    const multiplier = 1;
    const { isLoading, data } = useShootsQuery();
    const formatter = new Intl.NumberFormat('el-EL');

    if (isLoading)
        return (
            <Paper style={{ padding: "10px", marginBottom: "12px" }}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Συνολική Κάρπωση
                </Typography>
                <Box textAlign="center" p={2}>
                    <CircularProgress />
                </Box>
            </Paper>
        )

    return (
        <Paper style={{ padding: "10px", marginBottom: "12px" }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Συνολική Κάρπωση
            </Typography>
            <Typography component="p" variant="h4">
                {isLoading ? <LinearProgress /> : formatter.format(data?.total * multiplier)}
            </Typography>
            <Typography color="textSecondary">
                {isLoading ? "" : "θηράματα"}
            </Typography>

            <MyProgressBar cnt={data?.total * multiplier} limit={data?.period_limit} />
        </Paper>
    );
}

export default Info;