import React from 'react';
import { Box, CssBaseline, AppBar, Typography, Toolbar, Grid } from '@material-ui/core';
import Map from "./Map/Map";
import Info from './Info/Info';
import InfoList from "./InfoList";

function Home(props) {  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', background: "#eee" }}>
      <CssBaseline />
      <AppBar position="static" style={{background:"linear-gradient(125deg, rgb(67,91,24) 0%, rgb(67,91,24) 80%, rgb(186,184,122) 100%)"}}>
        <Toolbar>
          <Typography variant="h6" style={{padding:"10px"}}>
            Πλατφόρμα συλλογής/κοινοποίησης Δεδομένων του Μηχανισμού Προσαρμοσμένης Κάρπωσης (AHM) του Τρυγονιού (Streptopelia turtur)  στην Ελλάδα.
            <br />
            Data collection/reporting platform of Adaptive Harvest Management (AHM) Mechanism of Turtle dove (Streptopelia turtur) in Greece.
          </Typography>
        </Toolbar>
      </AppBar>
      <Box style={{ flexGrow: "1", margin: "12px", display: "flex" }}>
        <Grid container style={{ flexGrow: "1" }} spacing={2}>
          <Grid item container xs={12} sm={6} md={4} style={{ flexDirection: "column" }}>
            <Grid item>
              <Info />
            </Grid>
            <Grid item style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
              <InfoList />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={8} container>
            <Grid item xs={12} container>
              <Map />
            </Grid>
            {/* <Grid item xs={12} container>
              <MyPaper title="Date Plot" style={{ flexGrow: "1", padding: "10px", marginTop: "12px" }} />
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
    </Box>);
}

export default Home;