import { Box } from "@material-ui/core";
import { useContext } from "react";
import { useShootsQuery } from "../queries";
import { ManagerContext } from "./Manager";
import { formatDate, getSum } from "./utils";

function ManagerMapTooltip({ dataDays }) {
    const { selectedDate, enabledTool } = useContext(ManagerContext);
    const selectedDateTo = dataDays[dataDays.indexOf(selectedDate) - 6] || dataDays[0];
    const formatter = new Intl.NumberFormat('el-EL');
    const { data } = useShootsQuery(true);

    return <Box style={{ position: "absolute", top: "10px", left: "10px", background: "#ffffffaa", padding: "10px", fontSize:"1.2em", fontWeight:"bold" }}>
        {selectedDate && `${formatDate(selectedDate)} - ${formatDate(selectedDateTo)}`}
        {selectedDate ? <br /> : "Συνολική "}
        Κάρπωση: {formatter.format(getSum(data?.details, 0, selectedDate, dataDays, enabledTool))}
    </Box>;
}


export default ManagerMapTooltip;