import React from 'react';
import { useState } from 'react';
import MapGL, { Source, Layer } from 'react-map-gl';
import { Paper } from '@material-ui/core';
import { useShootsQuery } from '../../queries';
const MAPBOX_TOKEN = 'pk.eyJ1Ijoic21lY2h0IiwiYSI6ImNrcXl5a2tkNTAyNjcycGxnbHl5YmZjcTgifQ.DyMTuFPOmKfCauOgi0N0zw';

function makeGeoJSON(data) {
  if (!data) return null;
  return {
    type: 'FeatureCollection',
    features: data.filter(f => f.lng !== 0).map(feature => {
      return {
        "type": "Feature",
        "properties": {
          "id": feature.id,
          "value": feature.cnt
        },
        "geometry": {
          "type": "Point",
          "coordinates": [feature.lng, feature.lat]
        }
      }
    })
  }
};

function Map() {
  const [viewport, setViewport] = useState({
    latitude: 39,
    longitude: 23.5,
    zoom: 5,
    maxZoom: 9
  });
  const { data } = useShootsQuery();

  return (
    <Paper style={{ flexGrow: "1", minHeight:"400px" }}>
      <MapGL
        {...viewport}
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
        width='100%'
        height='100%'
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        <Source type='geojson' data={makeGeoJSON(data?.details)}>
          <Layer {...{
            type: 'heatmap',
            paint: {
              'heatmap-intensity': 1,
              'heatmap-radius': 10,
              'heatmap-weight': ['interpolate', ['linear'], ['get', 'value'], 0, 0, 6, 2],
              'heatmap-opacity': 1,
            },
          }} />
        </Source>
      </MapGL>
    </Paper>
  )
}

export default Map;