import React, { useState } from 'react';
import { Box, CssBaseline, TextField, Paper, Button } from '@material-ui/core';



function Login() {
    const [keyCode, setKeyCode] = useState("");
    const [email, setEmail] = useState("");
    const [completed, setCompleted] = useState(false);

    const postLogin = async () => {
        const postData = {
            email: email,
            sendMail: true,
            secret: keyCode
        }
        const res = await fetch("/api/check-admin", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        const resObj = await res.json()
        if(resObj.err){
            alert(resObj.err);            
        }
        else{
            setCompleted(true);
        }
    } 

    const isSubmitBtnDisabled = () => {
        if (!keyCode) return true;
        if (!email) return true;
        return false;
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        background: "linear-gradient(125deg, rgb(67,91,24) 0%, rgb(67,91,24) 50%, rgb(186,184,122) 100%)"
    }}>
        <CssBaseline />
        <Box sx={{ maxWidth: "500px", margin: "auto", width: "90%" }}>
            <Paper style={{ background: "linear-gradient(180deg, black 0%, black 50%, #444 100%)", color: "white", borderRadius: "20px", overflow: "hidden", boxShadow: "0px 0px 20px black" }}>
                <img alt="" src="/trigoni_header.jpg" style={{ width: "100%" }} />
                <Box p={2}>
                    <p style={{ marginTop: "-10px", marginBottom: "50px", fontWeight: "bold", color: "white", textAlign: "center", fontSize: "1.1em" }}>
                        Πλατφόρμα συλλογής/κοινοποίησης Δεδομένων του Μηχανισμού Προσαρμοσμένης Κάρπωσης (AHM) του Τρυγονιού (Streptopelia turtur)  στην Ελλάδα.
                        <br /><br />
                        Data collection/reporting platform of Adaptive Harvest Management (AHM) Mechanism of Turtle dove (Streptopelia turtur) in Greece.
                    </p>
                    {completed ?
                        <p style={{ fontWeight: "bold", color: "white", textAlign: "center", fontSize: "1.4em" }}>
                            Ο λογαριασμός σας ενεργοποιήθηκε.<br />Θα λάβετε eMail με αναλυτικές οδηγίες.
                        </p>
                        :
                        <>
                            <Paper style={{ marginBottom: "10px" }}>
                                <TextField type="password" value={keyCode} onChange={(e) => setKeyCode(e.target.value)} fullWidth id="outlined-basic" placeholder="Κλειδάριθμος" variant="outlined" />
                            </Paper>
                            <Paper style={{ marginBottom: "10px" }}>
                                <TextField value={email} onChange={(e) => setEmail(e.target.value)} fullWidth id="outlined-basic" placeholder="e-Mail" variant="outlined" />
                            </Paper>
                            <Button onClick={() => postLogin()} size="large" fullWidth variant="contained" disabled={isSubmitBtnDisabled()}>ΕΝΕΡΓΟΠΟΙΗΣΗ</Button>
                        </>
                    }
                </Box>
            </Paper>
        </Box>
    </Box>;
}


export default Login;