import React from 'react';
import { Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Box } from '@material-ui/core';
import { useShootsQuery } from '../../queries';

function formatDate(dt) {
    return `${dt.substr(7, 2)}/${dt.substr(5, 2)}/${dt.substr(1, 4)}`;
}

function emptyDays(){

    const params = new URLSearchParams(window.location.search);
    const days = Number(params.get('days') || 30);

    const dates = [...Array(days)].map((_, i) => {
        const d = new Date()
        d.setDate(d.getDate() - i)
        let dd = d.getDate();
        let mm = d.getMonth()+1;
        const yyyy = d.getFullYear();
        if(dd<10) {dd='0'+dd}
        if(mm<10) {mm='0'+mm}
        return `>${yyyy}${mm}${dd}`;        
    });
    return dates.reduce((prev, row, idx) => ({
        ...prev, [row]: 0
    }), {});
}

function InfoList() {
    const formatter = new Intl.NumberFormat('el-EL');

    const { data } = useShootsQuery();
    const dataGrouped = data?.details?.reduce((prev, row, idx) => ({
        ...prev, [row.date]: (prev[row.date] || 0) + row.cnt
    }), emptyDays()) || {};

    return (

        <Paper style={{ flexGrow: "1", minHeight:"400px", padding: "10px", display: "flex", flexDirection: "column" }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Καταχωρήσεις ανά Ημέρα
            </Typography>
            <Box style={{ flexGrow: "1", height: "1px", overflow: "hidden" }}>
                <TableContainer style={{ height: "100%", width: "100%" }}>
                    <Table stickyHeader size="small" style={{ maxHeight: "100%" }} >
                        <TableHead>
                            <TableRow>
                                <TableCell>Ημερομηνία</TableCell>
                                <TableCell>Πλήθος</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(dataGrouped).sort().reverse().map((key) => (
                                <TableRow key={key}>
                                    <TableCell>{formatDate(key)}</TableCell>
                                    <TableCell>{formatter.format(dataGrouped[key])}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Paper>
    );
}

export default InfoList;
