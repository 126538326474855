import React, {useState, useContext} from 'react';
import { Typography, Paper, Grid, TextField, Button } from '@material-ui/core';
import ManagerAddCounter from '../Manager/ManagerAddCounter';
import { useShootsQuery } from '../queries';
import { ManagerContext } from '../Manager/Manager';

function SmsForm() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [qrCode, setQRCode] = useState("");
    const { addedShoots, latLng } = useContext(ManagerContext);
    const { refetch } = useShootsQuery(true, true);

    const isBtnDisabled = () => {
        if(!qrCode)return true;
        if(!phoneNumber)return true;
        if(latLng[0] === 0)return true;
        return false;
    }

    const submitSMS = () => {
        const today = new Date();
        const y = today.getFullYear();
        const m = today.getMonth() + 1;
        const d = today.getDate();
        const mz = m > 9 ? "" : "0";
        const dz = d > 9 ? "" : "0";
        const dt = `>${y}${mz}${m}${dz}${d}`;
        fetch("/api/set-shoot", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            cnt: addedShoots,
            dt,
            qr: qrCode,
            sms_num: phoneNumber,
            lat: latLng[0],
            lng: latLng[1]
          })
        }).then(() => { refetch() });
      };
    
    return (
        <Paper style={{ padding: "10px", marginBottom: "12px" }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Καταχώρηση SMS
            </Typography>
            <Grid container spacing={2}>
            <Grid item xs={12}>
        <TextField variant="outlined" label="QR Code" fullWidth value={qrCode} onChange={event => setQRCode(event.target.value) } />
      </Grid>
      <Grid item xs={6}>
      <TextField variant="outlined" label="Τηλέφωνο" fullWidth value={phoneNumber} onChange={event => setPhoneNumber(event.target.value) } />
      </Grid>

                <Grid item xs={6}>
                    <ManagerAddCounter/>
                    <Button onClick={submitSMS} disabled={isBtnDisabled()} size="large" color="primary" fullWidth variant="contained" style={{marginTop:"12px"}}>ΚΑΤΑΧΩΡΗΣΗ</Button>
                </Grid>
            </Grid>
            
        </Paper>
    );
}

export default SmsForm;