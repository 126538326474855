import { Button, ButtonGroup, Box } from "@material-ui/core";
import { useContext } from "react";
import { ManagerContext } from "./Manager";

function ManagerAddCounter() {
    const { setAddedShoots, addedShoots } = useContext(ManagerContext);

    const handleSetShoot = (indic) => {
        let shoots = addedShoots + indic;
        if (shoots > 4) shoots = 4;
        if (shoots < 1) shoots = 1;
        setAddedShoots(shoots);
    }

    return (
        <Box mt={1}>
            <ButtonGroup fullWidth size="small">
                <Button onClick={() => handleSetShoot(-1)}>-</Button>
                <Button disabled>{addedShoots}</Button>
                <Button onClick={() => handleSetShoot(1)}>+</Button>
            </ButtonGroup>
        </Box>
    )
}

export default ManagerAddCounter;