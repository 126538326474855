import { useQuery } from "react-query"

export const useShootsQuery = (showAll, smsOnly) => {
    const params = new URLSearchParams(window.location.search);
    const userKey = params.get('key');
    const all=Boolean(showAll);
    const sms=Boolean(smsOnly);

    return useQuery('shoots', () => fetch(        
        `/api/get-shoots`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userKey, all, sms})
        }
    ).then((res) => res.json()), {
        refetchOnWindowFocus: false
    })
}

export const useAdminsQuery = (secret) => {
    return useQuery(['admins', secret], () => fetch(
        "/api/admin-list", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({secret})
        }
    ).then((res) => res.json()), {
        refetchOnWindowFocus: false
    })
}

export const useGeoOptionsQuery = () => {
    const params = new URLSearchParams(window.location.search);
    const userKey = params.get('key');

    return useQuery('geoOptions', () => fetch(        
        `/api/get-geo-options`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userKey})
        }
    ).then((res) => res.json()), {
        refetchOnWindowFocus: false
    })
}
