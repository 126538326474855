import { Box, CssBaseline, Toolbar, AppBar, Typography, Paper } from "@material-ui/core";
import { createContext, useState } from "react";
import AddAdmin from "./AddAdmin";
import AdminList from "./AdminList";

export const AdminsContext = createContext({});

function Admins() {

    const [showSendEmail, setShowSendEmail] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [secret, setSecret] = useState("");

    const contextValues = {
        showSendEmail, setShowSendEmail, 
        showDelete, setShowDelete,
        secret, setSecret
    }

    return (
        <AdminsContext.Provider value={contextValues}>
            <Box sx={{ display: 'flex', flexDirection: 'column', background: "#eee" }}>
                <CssBaseline />
                <AppBar position="static" style={{ background: "linear-gradient(125deg, rgb(67,91,24) 0%, rgb(67,91,24) 80%, rgb(186,184,122) 100%)" }}>
                    <Toolbar>
                        <Typography variant="h6" style={{ padding: "10px" }}>
                            Πλατφόρμα συλλογής/κοινοποίησης Δεδομένων του Μηχανισμού Προσαρμοσμένης Κάρπωσης (AHM) του Τρυγονιού (Streptopelia turtur)  στην Ελλάδα.
                            <br />
                            Data collection/reporting platform of Adaptive Harvest Management (AHM) Mechanism of Turtle dove (Streptopelia turtur) in Greece.
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box style={{ flexGrow: "1", margin: "12px", display: "flex" }}>
                    <Paper style={{ height: "100%", width: "100%", padding: "0px 20px" }}>
                        <h2>Λίστα Διαβαθμισμένων Χρηστών</h2>
                        <AdminList />
                        <AddAdmin />
                    </Paper>
                </Box>
            </Box>
        </AdminsContext.Provider>
    );
}

export default Admins;