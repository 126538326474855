import React from "react";
import { useState, useContext } from "react";
import MapGL, { Source, Layer, Marker } from "react-map-gl";
import { Paper, Box } from "@material-ui/core";
import { useShootsQuery } from "../queries";
import { ManagerContext } from "../Manager/Manager";
import AddLocationIcon from "@material-ui/icons/AddLocation";

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic21lY2h0IiwiYSI6ImNrcXl5a2tkNTAyNjcycGxnbHl5YmZjcTgifQ.DyMTuFPOmKfCauOgi0N0zw";

function makeGeoJSON(data) {
  if (!data) return null;
  return {
    type: "FeatureCollection",
    features: data
      .filter(f => f.lng !== 0)
      .map(feature => {
        return {
          type: "Feature",
          properties: {
            id: feature.id,
            value: feature.cnt
          },
          geometry: {
            type: "Point",
            coordinates: [feature.lng, feature.lat]
          }
        };
      })
  };
}

function SmsMap() {
  const { latLng, setLatLng } = useContext(ManagerContext);

  const [viewport, setViewport] = useState({
    latitude: 39,
    longitude: 23.5,
    zoom: 5,
    maxZoom: 15
  });
  const { data } = useShootsQuery(true, true);

  const handleLatLng = e => {
    setLatLng([e.lngLat[1], e.lngLat[0]]);
  };

  return (
    <Paper style={{ flexGrow: "1", minHeight: "400px" }}>
      <MapGL
        {...viewport}
        onViewportChange={viewport => {
          setViewport(viewport);
        }}
        width="100%"
        height="100%"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onClick={handleLatLng}
      >
        <Source type="geojson" data={makeGeoJSON(data?.details)}>
          <Layer
            {...{
              type: "heatmap",
              paint: {
                "heatmap-intensity": 1,
                "heatmap-radius": 10,
                "heatmap-weight": [
                  "interpolate",
                  ["linear"],
                  ["get", "value"],
                  0,
                  0,
                  6,
                  2
                ],
                "heatmap-opacity": 1
              }
            }}
          />
        </Source>
        {latLng[1] && (
          <Marker longitude={latLng[1]} latitude={latLng[0]}>
            <AddLocationIcon
              style={{
                color: "black",
                fontSize: "2em",
                transform: "translate(-50%,-100%)"
              }}
            />
          </Marker>
        )}
      </MapGL>
    </Paper>
  );
}

export default SmsMap;
