import React, { useContext, useState } from 'react';
import { Grid, Button, Menu, MenuItem, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import NatureIcon from '@material-ui/icons/Nature';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useGeoOptionsQuery } from '../queries';
import { ManagerContext } from './Manager';
import ManagerAddCounter from './ManagerAddCounter';
import ManagerDateRange from './ManagerDateRange';


function ManagerActions({dataDays}) {
  const { enabledTool, setEnabledTool } = useContext(ManagerContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { data: geoOptions, refetch: refetchGeoOptions } = useGeoOptionsQuery();


  const handleClickZoneBtn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectZone = (selected_zone) => {
    const params = new URLSearchParams(window.location.search);
    const userKey = params.get('key');
    fetch("/api/set-geo-options", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        selected_zone,
        userKey
      })
    }).then(() => { refetchGeoOptions() });

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleBlockUnlocatedChange = (block_unlocated) => {
    const params = new URLSearchParams(window.location.search);
    const userKey = params.get('key');
    fetch("/api/set-geo-options", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        block_unlocated,
        userKey
      })
    }).then(() => { refetchGeoOptions() });
  }

  const options = [
    'Χωρίς Ελεγχόμενες Περιοχές',
    'Ελεγχόμενες Περιοχές έως τις 15/09',
    'Ελεγχόμενες Περιοχές μετά τις 15/09'
  ];

  return (<Grid item container spacing={2} style={{ marginBottom: "2px" }}>
    <Grid item xs={3}>
      <Button onClick={() => setEnabledTool(enabledTool === 1 ? 0 : 1)} color={enabledTool === 1 ? "primary" : "default"} variant="contained" fullWidth><AddLocationIcon /></Button>
      {enabledTool === 1 &&
        <ManagerAddCounter />
      }
    </Grid>
    <Grid item xs={3}>
      <Button onClick={() => setEnabledTool(enabledTool === 2 ? 0 : 2)} color={enabledTool === 2 ? "primary" : "default"} variant="contained" fullWidth><SelectAllIcon /></Button>
      {enabledTool === 2 &&
        <FormControlLabel
          control={<Checkbox checked={geoOptions?.block_unlocated} onChange={e => handleBlockUnlocatedChange(e.target.checked)} />}
          label={<Typography style={{fontSize:"0.8em"}} >Αποκλεισμός Απροσδιόριστων</Typography>}
        />
      }
    </Grid>
    <Grid item xs={3}>
      <Button onClick={handleClickZoneBtn} variant="contained" fullWidth><NatureIcon /></Button>
    </Grid>
    <Grid item xs={3}>
      <Button onClick={() => setEnabledTool(enabledTool === 4 ? 0 : 4)} color={enabledTool === 4 ? "primary" : "default"} variant="contained" fullWidth><DateRangeIcon /></Button>
    </Grid>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {options.map((option, index) => (
        <MenuItem
          key={option}
          selected={index === geoOptions?.selected_zone}
          onClick={() => handleSelectZone(index)}
        >
          {option}
        </MenuItem>
      ))}
    </Menu>
      {enabledTool === 4 &&
        <ManagerDateRange dataDays={dataDays} />
      }
  </Grid>
  );
}

export default ManagerActions;