import { useCallback, useEffect, useRef, useState } from 'react';
import { Editor, DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import { useGeoOptionsQuery } from '../queries';
import { makeBlockedAreasGeoJSON } from './ManagerMap';
import { getFeatureStyle, getEditHandleStyle } from './style';

function ManagerMapEditor() {

  const [editMode, setEditMode] = useState(null);
  const [addNewPolygon, setAddNewPolygon] = useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const { data: geoOptions,  refetch: refetchGeoOptions } = useGeoOptionsQuery();
  const editorRef = useRef(null);

  useEffect(() => {
    if(!editorRef?.current)return;
    if(!geoOptions?.blocked_area)return;
    if(editorRef.current.getFeatures().length > 0)return;
    editorRef.current.addFeatures(makeBlockedAreasGeoJSON(geoOptions.blocked_area).features);
    setEditMode(new EditingMode());
  }, [editorRef, geoOptions]);

  const savePolygons = () => {
    const blocked_area = editorRef.current.getFeatures().map(f => f.geometry.coordinates);
    const params = new URLSearchParams(window.location.search);
    const userKey = params.get('key');
    fetch("/api/set-geo-options", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        blocked_area,
        userKey
      })
    }).then(() => { refetchGeoOptions() });
  };

  const onSelect = useCallback(options => {
    setSelectedFeatureIndex(options && options.selectedFeatureIndex);
  }, []);

  const onDelete = useCallback(() => {
    if (selectedFeatureIndex !== null && selectedFeatureIndex >= 0) {
      editorRef.current.deleteFeatures(selectedFeatureIndex);
    }
  }, [selectedFeatureIndex]);

  const onUpdate = useCallback(({ editType }) => {
    if (editType === 'addFeature') {
      setEditMode(new EditingMode());
      setAddNewPolygon(false);
    }
  }, []);

  const drawTools = (
    <div className="mapboxgl-ctrl-top-right">
      <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
        <button
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
          title="Add Polygon"
          onClick={() => { setEditMode(new DrawPolygonMode()); setAddNewPolygon(true); }}
          style={addNewPolygon ? { backgroundColor: "rgba(0,0,0,0.1)" } : {}}
        />
        <button
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
          title="Delete Selected Polygon"
          onClick={onDelete}
        />
        <button
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_combine"
          title="Save"
          onClick={savePolygons}
        />
      </div>
    </div>
  );

  return <>
    <Editor
      ref={editorRef}
      style={{ width: '100%', height: '100%' }}
      clickRadius={12}
      mode={editMode}
      onSelect={onSelect}
      onUpdate={onUpdate}
      editHandleShape={'circle'}
      featureStyle={getFeatureStyle}
      editHandleStyle={getEditHandleStyle}
      
    />
    {drawTools}
  </>;
}

export default ManagerMapEditor;