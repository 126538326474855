import React, { createContext, useState } from 'react';
import { Box, CssBaseline, AppBar, Typography, Toolbar, Grid } from '@material-ui/core';
import ManagerMap from './ManagerMap';
import ManagerInfo from './ManagerInfo';
import ManagerActions from './ManagerActions';
import ManagerInfoList from './ManagerInfoList';
import { useShootsQuery } from '../queries';

export const ManagerContext = createContext({});

function Manager(props) {

  const [dispStatus, setDispStatus] = useState([true, true, true, true, true]);
  const [enabledTool, setEnabledTool] = useState(0); // 0: None, 1: Add Shoot, 2: Draw Polygon
  const [blockedAreas, setBlockedAreas] = useState(1);
  const [addedShoots, setAddedShoots] = useState(4);
  const [selectedDate, setSelectedDate] = useState(null);
  const [autoPlayDateRange, setAutoPlayDateRange] = useState(false);
  const contextValues = {
    dispStatus, setDispStatus,
    enabledTool, setEnabledTool,
    blockedAreas, setBlockedAreas,
    addedShoots, setAddedShoots,
    selectedDate, setSelectedDate,
    autoPlayDateRange, setAutoPlayDateRange
  }
  const { data } = useShootsQuery();
  
  function emptyDays() {
    const params = new URLSearchParams(window.location.search);
    const days = Number(params.get('days') || 30);

    const dates = [...Array(days)].map((_, i) => {
      const d = new Date()
      d.setDate(d.getDate() - i)
      let dd = d.getDate();
      let mm = d.getMonth() + 1;
      const yyyy = d.getFullYear();
      if (dd < 10) { dd = '0' + dd }
      if (mm < 10) { mm = '0' + mm }
      return `>${yyyy}${mm}${dd}`;
    });
    return dates.reduce((prev, row) => ({
      ...prev, [row]: { shoots: 0, users: 0, manualUsers: 0, manualShoots: 0 }
    }), {});
  }

  function reduceRow(prev, row, dispStatus) {
    const rowCnt = dispStatus[row.is_fake] ? row.cnt : 0;
    const rowOne = dispStatus[row.is_fake] ? 1 : 0;

    if (!prev) return {
      shoots: rowCnt,
      users: row.user_code?.startsWith("ADM-INS-HOT") ? 0 : rowOne,
      manualUsers: row.user_code?.startsWith("ADM-INS-HOT") ? rowOne : 0,
      manualShoots: row.user_code?.startsWith("ADM-INS-HOT") ? rowCnt : 0
    };
    return ({
      shoots: prev.shoots + rowCnt,
      users: row.user_code?.startsWith("ADM-INS-HOT") ? prev.users : prev.users + rowOne,
      manualUsers: row.user_code?.startsWith("ADM-INS-HOT") ? prev.manualUsers + rowOne : prev.manualUsers,
      manualShoots: row.user_code?.startsWith("ADM-INS-HOT") ? prev.manualShoots + rowCnt : prev.manualShoots
    })
  }

  const dataGrouped = data?.details?.reduce((prev, row, idx) => ({
    ...prev, [row.date]: reduceRow(prev[row.date], row, dispStatus)
  }), emptyDays()) || {};

  const dataDays = Object.keys(dataGrouped).sort().reverse();

  return (
    <ManagerContext.Provider value={contextValues}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', background: "#eee" }}>
        <CssBaseline />
        <AppBar position="static" style={{ background: "linear-gradient(125deg, rgb(67,91,24) 0%, rgb(67,91,24) 80%, rgb(186,184,122) 100%)" }}>
          <Toolbar>
            <Typography variant="h6" style={{ padding: "10px" }}>
              Πλατφόρμα συλλογής/κοινοποίησης Δεδομένων του Μηχανισμού Προσαρμοσμένης Κάρπωσης (AHM) του Τρυγονιού (Streptopelia turtur)  στην Ελλάδα.
              <br />
              Data collection/reporting platform of Adaptive Harvest Management (AHM) Mechanism of Turtle dove (Streptopelia turtur) in Greece.
            </Typography>
          </Toolbar>
        </AppBar>
        <Box style={{ flexGrow: "1", margin: "12px", display: "flex" }}>
          <Grid container style={{ flexGrow: "1" }} spacing={2}>
            <Grid item container xs={12} sm={6} md={5} lg={4} xl={3} style={{ flexDirection: "column" }}>
              <ManagerActions dataDays={dataDays} />
              <Grid item>
                <ManagerInfo dataDays={dataDays} />
              </Grid>
              <Grid item style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
                <ManagerInfoList dataGrouped={dataGrouped} dataDays={dataDays}/>
              </Grid>
              {/* <Grid item>
                <ManagerPlot />
              </Grid> */}
            </Grid>
            <Grid item xs={12} sm={6} md={7} lg={8} xl={9} container>
              <Grid item xs={12} style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
                <ManagerMap dataDays={dataDays} />
              </Grid>
              {/* <Grid item xs={12}>
                <ManagerPlot />
              </Grid> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ManagerContext.Provider>);
}

export default Manager;