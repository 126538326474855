import React, { createContext, useState } from "react";
import {
  Box,
  CssBaseline,
  AppBar,
  Typography,
  Toolbar,
  Grid
} from "@material-ui/core";
import SmsForm from "./SmsForm";
import SmsMap from "./SmsMap";
import SmsList from "./SmsList";
import { ManagerContext } from '../Manager/Manager';

function Sms(props) {
  const [addedShoots, setAddedShoots] = useState(4);
  const [latLng, setLatLng] = useState([0,0]);
  const contextValues = {
    addedShoots,
    setAddedShoots,
    latLng,
    setLatLng
  };
  return (
    <ManagerContext.Provider value={contextValues}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          background: "#eee"
        }}
      >
        <CssBaseline />
        <AppBar
          position="static"
          style={{
            background:
              "linear-gradient(125deg, rgb(67,91,24) 0%, rgb(67,91,24) 80%, rgb(186,184,122) 100%)"
          }}
        >
          <Toolbar>
            <Typography variant="h6" style={{ padding: "10px" }}>
              Πλατφόρμα συλλογής/κοινοποίησης Δεδομένων του Μηχανισμού
              Προσαρμοσμένης Κάρπωσης (AHM) του Τρυγονιού (Streptopelia turtur)
              στην Ελλάδα.
              <br />
              Data collection/reporting platform of Adaptive Harvest Management
              (AHM) Mechanism of Turtle dove (Streptopelia turtur) in Greece.
            </Typography>
          </Toolbar>
        </AppBar>
        <Box style={{ flexGrow: "1", margin: "12px", display: "flex" }}>
          <Grid container style={{ flexGrow: "1" }} spacing={2}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              style={{ flexDirection: "column" }}
            >
              <Grid item>
                <SmsForm />
              </Grid>
              <Grid
                item
                style={{
                  flexGrow: "1",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <SmsList />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} container>
              <Grid item xs={12} container>
                <SmsMap />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ManagerContext.Provider>
  );
}

export default Sms;
