import { Box, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useContext, useState } from "react";
import { useAdminsQuery } from "../queries";
import { AdminsContext } from "./Admins";

function AddAdmin() {
    const [newMail, setNewMail] = useState("");
    const [newSecret, setNewSecret] = useState("");
    const { showSendEmail, setShowSendEmail,
        showDelete, setShowDelete, 
        secret, setSecret } = useContext(AdminsContext);
    const { refetch } = useAdminsQuery(secret);

    const postNewMail = async () => {

        const postData = {
            email: newMail,
            sendMail: false,
            secret
        }
        const res = await fetch("/api/check-admin", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        const resObj = await res.json();
        if(resObj.err)setSecret("");
        setNewMail("");
        refetch();
    }

    if(!secret)
    return (<Box marginY={2}>
        <FormControl fullWidth variant="outlined" style={{ maxWidth: "400px" }}>
            <InputLabel htmlFor="outlined-adornment-amount">Κλειδάριθμος</InputLabel>
            <OutlinedInput variant="outlined"
                labelWidth={110}
                value={newSecret}
                onChange={(ev) => setNewSecret(ev.target.value)}
                endAdornment={
                    newSecret &&
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setSecret(newSecret)}
                            edge="end"
                        >
                            <VpnKeyIcon />
                        </IconButton>
                    </InputAdornment>
                } />
        </FormControl>
    </Box>);

    return (<Box marginY={2}>
        <FormControl fullWidth variant="outlined" style={{ maxWidth: "400px" }}>
            <InputLabel htmlFor="outlined-adornment-amount">Νεό eMail</InputLabel>
            <OutlinedInput variant="outlined"
                labelWidth={80}
                value={newMail}
                onChange={(ev) => setNewMail(ev.target.value)}
                endAdornment={
                    newMail &&
                    <InputAdornment position="end">
                        <IconButton
                            onClick={postNewMail}
                            edge="end"
                        >
                            <Save />
                        </IconButton>
                    </InputAdornment>
                } />
        </FormControl>
        <FormControlLabel
            style={{padding:"5px 20px"}}
        control={<Checkbox checked={showDelete} onChange={(e) => setShowDelete(e.target.checked)} name="Delete" />}
        label="Delete"
        />
        <FormControlLabel
            style={{padding:"5px 20px"}}
        control={<Checkbox checked={showSendEmail} onChange={(e) => setShowSendEmail(e.target.checked)} name="Send eMail" />}
        label="Send Mail"
        />
    </Box>);

}

export default AddAdmin;