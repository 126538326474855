import { Box, LinearProgress, Typography, withStyles } from "@material-ui/core";

function MyProgressBar (props)  {
    const formatter = new Intl.NumberFormat('el-EL');
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
        },
    }))(LinearProgress);

    return <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
            <BorderLinearProgress variant="determinate" value={props.cnt / props.limit * 100} />
        </Box>
        <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{formatter.format(props.limit)}</Typography>
        </Box>
    </Box>
}

export default MyProgressBar;