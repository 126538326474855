import { Box, Button, TextField, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";

function MsgForm() {
  const [msg, setMsg] = useState("");
  const [dayLimit, setDayLimit] = useState("");
  const [periodLimit, setPeriodLimit] = useState("");
  const [secret, setSecret] = useState("");

  useEffect(() => {
    fetch("/api/get-msg")
      .then(response => response.json())
      .then(data => {
        setMsg(data["msg"]); 
        setDayLimit(data["limit"]);
        setPeriodLimit(data["period_limit"]);
      });
  }, [])

  const handleMsgChange = (event) => {
    setMsg(event.target.value);
  };

  const handleDayLimitChange = (event) => {
    setDayLimit(event.target.value);
  };
  const handlePeriodLimitChange = (event) => {
    setPeriodLimit(event.target.value);
  };

  const handleSecretChange = (event) => {
    setSecret(event.target.value);
  };

  const doUpdate = () => {
    fetch("/api/set-msg", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ msg, secret, dayLimit, periodLimit })
    })
      .then(response => response.json())
      .then(data => setMsg(data["msg"]));

  }

  return <Box p={5} textAlign="right">
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField variant="outlined" label="Message" fullWidth value={msg} onChange={handleMsgChange} multiline />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField variant="outlined" fullWidth label="Day Limit" value={dayLimit} onChange={handleDayLimitChange} />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField variant="outlined" fullWidth label="Period Limit" value={periodLimit} onChange={handlePeriodLimitChange} />
      </Grid>
      <Grid item xs={6} md={5}>
        <TextField variant="outlined" fullWidth label="Secret" value={secret} onChange={handleSecretChange} multiline />
      </Grid>
      <Grid item xs={6} md={3}>
          <Button onClick={doUpdate}>Update</Button>
      </Grid>
    </Grid>
  </Box>;
}

export default MsgForm;