import { Button, Grid, Slider } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { ManagerContext } from "./Manager";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { formatDate } from "./utils";

function ManagerDateRange({ dataDays }) {
    const { autoPlayDateRange, setAutoPlayDateRange } = useContext(ManagerContext);
    const { selectedDate, setSelectedDate } = useContext(ManagerContext);
    const selDateIdx = dataDays.length - dataDays.indexOf(selectedDate) - 1;
    const selectedDateTo = dataDays[dataDays.indexOf(selectedDate) - 6] || dataDays[0];

    const handleSliderChange = (_, newValue) => {
        const newSelDayIdx = dataDays.length - newValue - 1;
        setSelectedDate(dataDays[newSelDayIdx]);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (autoPlayDateRange && selectedDate) {
                const key = dataDays.indexOf(selectedDate);
                const newDay = dataDays[key - 1];
                const newLastDay = dataDays[key - 7];
                if (newLastDay) setSelectedDate(newDay);
                else setAutoPlayDateRange(false);
            } else {
                setAutoPlayDateRange(false);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [autoPlayDateRange, selectedDate, setSelectedDate, dataDays, setAutoPlayDateRange]);

    return <>
        <Grid item xs={10} style={{ padding: "12px 20px" }}>
            <Slider
                fullWidth
                min={0}
                max={dataDays.length - 7}
                value={selDateIdx || 0}
                onChange={handleSliderChange}
            />
            {selectedDate && `${formatDate(selectedDate)} - ${formatDate(selectedDateTo)}`}
        </Grid>
        <Grid item xs={2}>
            <Button onClick={() => setAutoPlayDateRange(!autoPlayDateRange)} fullWidth>
                {autoPlayDateRange ? <PauseIcon /> : <PlayArrowIcon />}
            </Button>
        </Grid>
    </>
}

export default ManagerDateRange;