export function formatDate(dt) {
    if (!dt) return "";
    return `${dt.substr(7, 2)}/${dt.substr(5, 2)}/${dt.substr(1, 4)}`;
}

export function isDateSelected(key, selectedDate, dataDays, enabledTool) {
    if (enabledTool !== 4) return key === selectedDate;
    const selDateIdx = dataDays.indexOf(selectedDate);
    if (selDateIdx < 0) return false;
    const keyIdx = dataDays.indexOf(key);
    return (keyIdx <= selDateIdx && keyIdx > selDateIdx - 7);
}

export function getSum(data, isFake, selectedDate, dataDays, enabledTool) {
    if (!data) return (null);    
    const cnt = data
        .filter(d => (d.is_fake === isFake || isFake === -1) && (!selectedDate || isDateSelected(d.date, selectedDate, dataDays, enabledTool)) )
        .reduce((prev, d) => prev + d.cnt, 0);
    return (cnt);
}