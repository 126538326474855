import React, { useContext } from 'react';
import { Typography, Paper, FormControlLabel, Checkbox } from '@material-ui/core';
import { useShootsQuery } from '../queries';
import { ManagerContext } from './Manager';
import { getSum } from './utils';


function ManagerInfo({dataDays}) {
    const { data } = useShootsQuery(true);
    const formatter = new Intl.NumberFormat('el-EL');
    const checkColors = ["#080", "#a00", "#00a", "#f40", "#888"]
    const { dispStatus, setDispStatus, selectedDate, enabledTool } = useContext(ManagerContext);

    const handleCheckBoxChange = (checked, idx) => {
        setDispStatus(dispStatus.map((c, i) => i === idx ? checked : c));
    };

    return (
        <Paper style={{ padding: "10px", marginBottom: "12px" }}>
            {["Κάρπωση", "Ελεγχόμενες Περιοχές", "Εκτός Χάρτη", "Αποκλεισμός", "Χωρίς Τοποθεσία"].map((title, idx) =>
                <Typography color="textSecondary" key={idx}>
                    <FormControlLabel
                        control={<Checkbox checked={dispStatus[idx]} onChange={e => handleCheckBoxChange(e.target.checked, idx)} style={{ color: checkColors[idx] }} />}
                        label={title}
                    />
                    <span style={{ float: "right", marginTop: "8px" }}>{formatter.format(getSum(data?.details, idx, selectedDate, dataDays, enabledTool))}</span>
                </Typography>
            )}
            <Typography color="textSecondary">
                Σύνολο: <span style={{ float: "right" }}>{formatter.format(getSum(data?.details, -1, selectedDate, dataDays, enabledTool))}</span>
            </Typography>

        </Paper>
    );
}

export default ManagerInfo;