import React from 'react';
import { Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Box } from '@material-ui/core';
import { useShootsQuery } from '../queries';

function formatDate(dt) {
    return `${dt.substr(7, 2)}/${dt.substr(5, 2)}/${dt.substr(1, 4)}`;
}

function SmsList() {

    const { data } = useShootsQuery(true, true);
    return (

        <Paper style={{ flexGrow: "1", minHeight:"400px", padding: "10px", display: "flex", flexDirection: "column" }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Καταχωρήσεις SMS
            </Typography>
            <Box style={{ flexGrow: "1", height: "1px", overflow: "hidden" }}>
                <TableContainer style={{ height: "100%", width: "100%" }}>
                    <Table stickyHeader size="small" style={{ maxHeight: "100%" }} >
                        <TableHead>
                            <TableRow>
                                <TableCell>Ημερομηνία</TableCell>
                                <TableCell>Πλήθος</TableCell>
                                <TableCell>QR</TableCell>
                                <TableCell>Τηλέφωνο</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data?.details || []).map((row, rowIdx) => (
                                <TableRow row={rowIdx}>
                                    <TableCell>{formatDate(row.date)}</TableCell>
                                    <TableCell>{row.cnt}</TableCell>
                                    <TableCell>{row.user_code}</TableCell>
                                    <TableCell>{row.sms_num}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Paper>
    );
}

export default SmsList;
