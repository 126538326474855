import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from "./Home/Home";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import MsgForm from "./MsgForm/MsgForm";
import Login from "./Login/Login";
import Admins from "./Admins/Admins";
import Manager from "./Manager/Manager";
import Sms from "./Sms/Sms";

const queryClient = new QueryClient()

function App() {
  const menu = [{ path: "/", cmp: <Login /> },
  { path: "/map", cmp: <Home /> },
  { path: "/msg", cmp: <MsgForm /> },
  { path: "/admins", cmp: <Admins /> },
  { path: "/manager", cmp: <Manager /> },
  { path: "/sms", cmp: <Sms /> },
]
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          {menu.map((m, idx) =>
            <Route key = {idx} exact path={m.path}>
              {m.cmp}
            </Route>
          )}
        </Switch>
      </Router>
    </QueryClientProvider>
  );
}

export default App;