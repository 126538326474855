import React, { useContext } from 'react';
import { Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Box } from '@material-ui/core';
import { ManagerContext } from './Manager';
import { formatDate, isDateSelected } from './utils';

function ManagerInfoList({dataGrouped, dataDays}) {
    const formatter = new Intl.NumberFormat('el-EL');
    const { selectedDate, setSelectedDate, enabledTool } = useContext(ManagerContext);

    const cellStyle = { paddingLeft: "2px", paddingRight: "2px", textAlign: "right" };
    
    return (

        <Paper style={{ minHeight: "400px", flexGrow: "1", padding: "10px", display: "flex", flexDirection: "column" }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Καταχωρήσεις ανά Ημέρα
            </Typography>
            <Box style={{ flexGrow: "1", height: "1px", overflow: "hidden" }}>
                <TableContainer style={{ height: "100%", width: "100%" }}>
                    <Table stickyHeader size="small" style={{ maxHeight: "100%" }} >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ ...cellStyle, textAlign: "left" }}>Ημερομηνία</TableCell>
                                <TableCell style={cellStyle}>Θηράματα</TableCell>
                                <TableCell style={cellStyle}>Χρήστες</TableCell>
                                <TableCell style={cellStyle}>Manual</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataDays.map((key, idx) => (
                                <TableRow
                                    key={key}
                                    style={{ cursor: "pointer", background: isDateSelected(key, selectedDate, dataDays, enabledTool) ? "#ddf" : "" }}
                                    onClick={() => setSelectedDate(selectedDate === key ? null : key)}
                                >
                                    <TableCell style={{ ...cellStyle, textAlign: "left" }}>{formatDate(key)}</TableCell>
                                    <TableCell style={cellStyle}>{formatter.format(dataGrouped[key].shoots)}</TableCell>
                                    <TableCell style={cellStyle}>{formatter.format(dataGrouped[key].users)}</TableCell>
                                    <TableCell style={cellStyle}>{formatter.format(dataGrouped[key].manualUsers)}({formatter.format(dataGrouped[key].manualShoots)})</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Paper>
    );
}

export default ManagerInfoList;
